import axios from 'axios';
import { TestConfig } from 'pages/api/ab-tests';

const AB_TESTS_ENDPOINT = '/api/ab-tests';

/**
 * Fetches AB test configurations by their IDs
 * @param ids - Array of AB test IDs to fetch
 * @returns Promise containing a record of test IDs to their configurations
 */
export const getAbTests = async ({
  ids,
}: {
  ids: string[];
}): Promise<Record<string, TestConfig>> => {
  try {
    const { data } = await axios.post<Record<string, TestConfig>>(
      AB_TESTS_ENDPOINT,
      { ids }
    );
    return data;
  } catch (error) {
    console.error('Failed to fetch AB tests:', error);
    return {};
  }
};
