import cn from 'classnames';
import { usePetContext } from 'contexts/pet';
import { useSegmentationContext } from 'contexts/segmentation';
import { EntryNavigationItem } from 'interfaces/navigation-data';
import NextLink from 'next/link';
import React from 'react';

interface ILinkProps {
  as?: string;
  className?: string;
  item: EntryNavigationItem;
  onClick?: () => void;
}
/**
 * Link component used in the desktop navigation
 */
const Link: React.FC<React.PropsWithChildren<ILinkProps>> = ({
  as,
  children,
  className,
  item,
  onClick,
}) => {
  const { current } = usePetContext();
  const { getSegmentationFromUrl, pushSegmentation } = useSegmentationContext();

  const onLinkClick = (item: EntryNavigationItem): void => {
    if (item.itemType === 'entry' || item.itemType === 'card') {
      const segmentation = getSegmentationFromUrl({
        url: item.url,
      });
      if (segmentation) {
        pushSegmentation({
          segmentation: segmentation.id,
        });
      }
      onClick?.();
    }
  };

  return (
    <NextLink passHref href={item.url} as={as}>
      <a
        data-test={`nav-${current}-${item.title}`}
        className={cn(
          'relative flex h-10 items-center px-2 pb-px text-sm font-medium text-primary-main hover:text-other-black',
          className
        )}
        onClick={() => onLinkClick(item)}
      >
        {children}
      </a>
    </NextLink>
  );
};

export default Link;
