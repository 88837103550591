export const getAbTestsByIdsQuery = /* GraphQL */ `
  query getAbTestsByIdsQuery($ids: [String!]) {
    experimentConfigCollection(where: { sys: { id_in: $ids } }, limit: 50) {
      items {
        sys {
          id
        }
        testData
        baseUrl
        redirectUrl
        ntExperiencesCollection(limit: 10) {
          items {
            sys {
              id
            }
            ntType
            ntConfig
            ntName
            ntAudience {
              ntAudienceId
              ntName
            }
            ntExperienceId
            ntVariantsCollection(limit: 5) {
              items {
                ... on ExperimentConfig {
                  sys {
                    id
                  }
                  testData
                  baseUrl
                  redirectUrl
                  __typename
                }
              }
            }
          }
        }
      }
    }
  }
`;
