import {
  EntryNavigationItem,
  INavigationData,
  NestedEntryNavigationItem,
  TrustLinkIf,
} from 'interfaces/navigation-data';
import { Gtm, gtm } from 'tracking/gtm';

export const commonMobileFooter: EntryNavigationItem[] = [
  {
    title: 'Über uns',
    url: '/pages/uber-uns',
    itemType: 'entry',
    reducedTextSize: true,
    dividerTop: true,
  },
  {
    title: 'FAQ',
    url: 'https://help.petsdeli.de/hc/de',
    itemType: 'entry',
    reducedTextSize: true,
  },
  {
    title: 'Markenbotschafter*in werden',
    url: '/pages/kooperationsprogramme',
    itemType: 'entry',
    reducedTextSize: true,
  },
  {
    title: 'Bonusprogramm',
    url: '/pages/bonusprogramm',
    itemType: 'entry',
    reducedTextSize: true,
  },
  {
    title: 'Jobs / Karriere',
    url: 'https://pets-deli.jobs.personio.de',
    itemType: 'entry',
    reducedTextSize: true,
  },
];

export const commonTrustbar: TrustLinkIf[] = [
  {
    title: 'Beratung',
    url: '/pages/unsere-futterberatung-online-und-telefonisch',
    target: '_self',
    rel: '',
  },
  {
    title: 'FAQ',
    url: 'https://help.petsdeli.de/hc/de',
    target: '_self',
    rel: '',
  },
  {
    title: 'Bonusprogramm',
    url: '/pages/bonusprogramm',
    target: '_self',
    rel: '',
  },
];

export const dogsCommonNeeds: Array<
  NestedEntryNavigationItem | EntryNavigationItem
> = [
  {
    title: 'Nach Bedürfnis',
    itemType: 'nested-entry',
    items: [
      {
        title: 'Wählerische Esser',
        url: '/collections/fur-hunde-sehr-waehlerisch',
        itemType: 'entry',
      },
      {
        title: 'Verdauungsprobleme',
        url: '/collections/fur-hunde-verdauungsprobleme',
        itemType: 'entry',
      },
      {
        title: 'Haut- & Fellprobleme',
        url: '/collections/fur-hunde-haut-fellprobleme',
        itemType: 'entry',
      },
      {
        url: '/collections/fur-hunde-unvertraeglichkeiten-allergien',
        title: 'Unverträglichkeiten',
        itemType: 'entry',
      },
      {
        url: '/collections/fur-hunde-uebergewicht',
        title: 'Übergewicht',
        itemType: 'entry',
      },
    ],
  },
  {
    title: 'Nach Alter',
    itemType: 'nested-entry',
    items: [
      {
        title: 'Welpe (0-1 Jahre)',
        url: '/collections/fur-hunde-welpen-und-junghunde',
        itemType: 'entry',
      },
      {
        title: 'Adult (1-8 Jahre)',
        url: '/collections/fur-hunde-adult',
        itemType: 'entry',
      },
      {
        title: 'Senior (8+ Jahre)',
        url: '/collections/fur-hunde-senior',
        itemType: 'entry',
      },
    ],
  },
];

export const dogsCommonBreeds: Array<
  NestedEntryNavigationItem | EntryNavigationItem
> = [
  {
    title: 'Nach Rasse',
    itemType: 'nested-entry',
    dividerBottom: true,
    items: [
      {
        title: 'Bulldogge',
        url: '/collections/fur-hunde-bulldogge',
        itemType: 'entry',
      },
      {
        title: 'Chihuahua',
        url: '/collections/fur-hunde-chihuahua',
        itemType: 'entry',
      },
      {
        title: 'Dackel',
        url: '/collections/fur-hunde-dackel',
        itemType: 'entry',
      },
      {
        title: 'Havaneser & Malteser',
        url: '/collections/fur-hunde-malteser',
        itemType: 'entry',
      },
      {
        title: 'Pudel & Pudelmix',
        url: '/collections/fur-hunde-pudel',
        itemType: 'entry',
      },
      {
        title: 'Retriever',
        url: '/collections/fur-hunde-retriever',
        itemType: 'entry',
      },
      {
        title: 'Schäferhund',
        url: '/collections/fur-hunde-schaeferhund',
        itemType: 'entry',
      },
      {
        title: 'Spitz',
        url: '/collections/fur-hunde-spitz',
        itemType: 'entry',
      },
      {
        title: 'Terrier',
        url: '/collections/fur-hunde-terrier',
        itemType: 'entry',
      },
      {
        title: 'Hütehund',
        url: '/collections/fur-hunde-huetehunde',
        itemType: 'entry',
      },
    ],
  },
];

export const dogsCommonFoodType: Array<
  NestedEntryNavigationItem | EntryNavigationItem
> = [
  {
    title: 'Nassfutter',
    itemType: 'entry',
    url: '/collections/fur-hunde-nassfutter',
  },
  {
    title: 'Trockenfutter',
    itemType: 'entry',
    url: '/collections/fur-hunde-trockenfutter',
  },
  {
    title: 'Snacks',
    itemType: 'nested-entry',
    dividerBottom: true,
    items: [
      {
        title: 'Alle Snacks',
        url: '/collections/fur-hunde-snacks',
        itemType: 'entry',
      },
      {
        title: 'Zahnpflege',
        url: '/collections/fur-hunde-snacks/zahnpflege',
        itemType: 'entry',
      },
      {
        title: 'Functional Snacks',
        url: '/collections/fur-hunde-snacks/functional-snacks',
        itemType: 'entry',
      },
      {
        title: 'Knochen & Kaurollen',
        url: '/collections/fur-hunde-snacks/knochen-and-kaurollen',
        itemType: 'entry',
      },
      {
        title: 'Kaustreifen & Sticks',
        url: '/collections/fur-hunde-snacks/kaustreifen-and-sticks',
        itemType: 'entry',
      },
      {
        title: 'Trainingssnacks',
        url: '/collections/fur-hunde-snacks/trainingssnacks',
        itemType: 'entry',
      },
      {
        title: 'Pasten & Cremesnacks',
        url: '/collections/fur-hunde-snacks/pasten-and-cremesnacks',
        itemType: 'entry',
      },
      {
        title: 'Toppers',
        url: '/collections/fur-hunde-snacks/toppers',
        itemType: 'entry',
      },
    ],
  },
];

export const dogsCommonMixed: Array<
  NestedEntryNavigationItem | EntryNavigationItem
> = [
  {
    title: 'Probierpakete',
    url: '/collections/fur-hunde-probierpakete',
    itemType: 'entry',
  },
  {
    title: 'Zubehör	',
    url: '/collections/fur-hunde-zubehor',
    itemType: 'entry',
  },
  {
    title: 'Gesundheit',
    url: '/collections/fur-hunde-gesundheit',
    itemType: 'entry',
  },
];

export const dogsSnack: Array<NestedEntryNavigationItem | EntryNavigationItem> =
  [
    {
      title: 'Snacks',
      itemType: 'nested-entry',
      items: [
        {
          title: 'Alle Snacks',
          url: '/collections/fur-hunde-snacks',
          itemType: 'entry',
        },
        {
          title: 'Zahnpflege',
          url: '/collections/fur-hunde-snacks/zahnpflege',
          itemType: 'entry',
        },
        {
          title: 'Functional Snacks',
          url: '/collections/fur-hunde-snacks/functional-snacks',
          itemType: 'entry',
        },
        {
          title: 'Knochen & Kaurollen',
          url: '/collections/fur-hunde-snacks/knochen-and-kaurollen',
          itemType: 'entry',
        },
        {
          title: 'Kaustreifen & Sticks',
          url: '/collections/fur-hunde-snacks/kaustreifen-and-sticks',
          itemType: 'entry',
        },
        {
          title: 'Trainingssnacks',
          url: '/collections/fur-hunde-snacks/trainingssnacks',
          itemType: 'entry',
        },
        {
          title: 'Pasten & Cremesnacks',
          url: '/collections/fur-hunde-snacks/pasten-and-cremesnacks',
          itemType: 'entry',
        },
        {
          title: 'Toppers',
          url: '/collections/fur-hunde-snacks/toppers',
          itemType: 'entry',
        },
      ],
    },
  ];

export const dogsCommonEc: Array<
  NestedEntryNavigationItem | EntryNavigationItem
> = [
  {
    title: 'Nassfutter',
    itemType: 'entry',
    url: '/collections/fur-hunde-nassfutter',
  },
  {
    title: 'Trockenfutter',
    itemType: 'entry',
    url: '/collections/fur-hunde-trockenfutter',
    dividerBottom: true,
  },
];

export const catsCommonNeeds: Array<
  NestedEntryNavigationItem | EntryNavigationItem
> = [
  {
    title: 'Spezielle Bedürfnisse',
    itemType: 'nested-entry',
    items: [
      {
        title: 'Sehr wählerisch',
        url: '/collections/fur-katzen-wahlerisch',
        itemType: 'entry',
      },
      {
        title: 'Verdauungsprobleme',
        url: '/collections/fur-katzen-verdauungsprobleme',
        itemType: 'entry',
      },
      {
        title: 'Freigänger',
        url: '/collections/fur-katzen-freiganger',
        itemType: 'entry',
      },
      {
        title: 'Übergewicht',
        url: '/collections/fur-katzen-ubergewicht',
        itemType: 'entry',
      },
      {
        title: 'Sterilisiert',
        url: '/collections/fur-katzen-sterilisiert',
        itemType: 'entry',
      },
    ],
  },
  {
    title: 'Nach Alter',
    itemType: 'nested-entry',
    dividerBottom: true,
    items: [
      {
        title: 'Kitten (0-1 Jahre)',
        url: '/collections/fur-katzen-kitten-und-junge-katze',
        itemType: 'entry',
      },
      {
        title: 'Adult (1-10 Jahre)',
        url: '/collections/fur-katzen-adult ',
        itemType: 'entry',
      },
      {
        title: 'Senior (10+ Jahre)',
        url: '/collections/fur-katzen-senior',
        itemType: 'entry',
      },
    ],
  },
];

export const catsCommonFoodType: Array<
  NestedEntryNavigationItem | EntryNavigationItem
> = [
  {
    title: 'Nassfutter',
    url: '/collections/fur-katzen-nassfutter',
    itemType: 'entry',
  },
  {
    title: 'Trockenfutter',
    url: '/collections/fur-katzen-trockenfutter',
    itemType: 'entry',
  },
  {
    title: 'Snacks',
    itemType: 'nested-entry',
    dividerBottom: true,
    items: [
      {
        title: 'Alle Snacks',
        url: '/collections/fur-katzen-snacks',
        itemType: 'entry',
      },
      {
        title: 'Creme Snacks',
        url: '/collections/fur-katzen-snacks/creme-snack',
        itemType: 'entry',
      },
      {
        title: 'Soft Snacks',
        url: '/collections/fur-katzen-snacks/soft-snacks',
        itemType: 'entry',
      },
      {
        title: 'Filet Snacks',
        url: '/collections/fur-katzen-snacks/filet-snack',
        itemType: 'entry',
      },
      {
        title: 'Suppen',
        url: '/collections/fur-katzen-snacks/suppe',
        itemType: 'entry',
      },
      {
        title: 'Sensitiv',
        url: '/collections/fur-katzen-snacks/sensitiv',
        itemType: 'entry',
      },
      {
        title: 'Pasten',
        url: '/collections/fur-katzen-snacks/pasten',
        itemType: 'entry',
      },
    ],
  },
];

export const catsCommonMixed: Array<
  NestedEntryNavigationItem | EntryNavigationItem
> = [
  {
    title: 'Probierpakete',
    itemType: 'entry',
    url: '/collections/probierpakete-fur-katzen',
  },
  {
    title: 'Gesundheit',
    url: '/collections/fur-katzen-nahrungserganzung',
    itemType: 'entry',
  },
];

export const footer = (): INavigationData['footer'] => {
  return {
    usefulInformation: [
      {
        title: 'Über Uns',
        url: '/pages/uber-uns',
      },
      {
        title: 'Was ist gesundes Tierfutter?',
        url: '/magazin/hunde/hunde-ernaehrung/was-ist-gesundes-tierfutter',
      },
      {
        title: 'Warum Premium-Tiernahrung?',
        as: '/pages/premium',
        url: '/pages/[handle]',
      },
      {
        title: 'Umfütterung',
        url: '/magazin/hunde/hunde-ernaehrung/umfutterung-von-hunden',
      },
      {
        title: 'Unsere Stores',
        as: '/pages/unsere-stores',
        url: '/pages/[handle]',
      },
    ],
    goodForYou: [
      {
        title: 'Freund*innen werben',
        as: '/pages/freund-innen-werben',
        url: '/pages/[handle]',
      },
      {
        title: 'Markenbotschafter*in werden',
        url: '/pages/markenbotschafter-in-werden',
        attrs: {
          onClick: () =>
            gtm({
              group: Gtm.GroupName.Component,
              name: Gtm.ComponentEventName.Click,
              data: {
                label: 'AmassadorProgramm-Footer',
              },
            }),
        },
      },
      {
        title: 'Vertriebspartner*in werden',
        as: '/pages/vertriebspartner-in-werden',
        url: '/pages/[handle]',
      },
      {
        title: 'Unser Spar-Abo',
        as: '/pages/unser-spar-abo',
        url: '/pages/[handle]',
      },
    ],
    help: [
      {
        title: 'Häufige Fragen',
        url: 'https://help.petsdeli.de/hc/de',
        attrs: {
          rel: 'noopener',
        },
        target: '_blank',
      },
      {
        title: 'Versand',
        url: '/pages/[handle]',
        as: '/pages/versand',
      },
      {
        title: 'Pets Deli im Handel',
        url: '/pages/[handle]',
        as: '/pages/haendlersuche',
      },
      {
        title: 'Züchter*innenprogramm',
        url: '/pages/[handle]',
        as: '/pages/zuchter-innen-programm',
      },
    ],
    legal: [
      {
        title: 'Jobs',
        url: '/pages/jobs',
      },
      {
        title: 'Impressum',
        url: '/pages/[handle]',
        as: '/pages/impressum',
      },
      {
        title: 'Datenschutz',
        url: '/pages/[handle]',
        as: '/pages/datenschutz',
      },
      {
        title: 'AGB und Widerruf',
        url: '/pages/[handle]',
        as: '/pages/agb-und-widerruf',
      },
    ],
  };
};
