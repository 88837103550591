import IconChevronRight from 'assets/icons/chevron-right-stroke.svg';
import cn from 'classnames';
import { usePetContext } from 'contexts/pet';
import { NavigationItem } from 'interfaces/navigation-data';
import Link from 'next/link';
import React from 'react';
interface INavigationLink {
  item: NavigationItem;
  className?: string;
  onClick: () => void;
}

const NavigationLink: React.FC<INavigationLink> = ({
  item,
  className,
  onClick,
}) => {
  const { current } = usePetContext();
  const NavItemWrapper = ({ children }): JSX.Element => {
    switch (item.itemType) {
      case 'targeted':
        return (
          <a
            data-test={`nav-${current}-${item.title}`}
            href={item.url}
            target={item.target}
            className="w-full"
            onClick={onClick}
            key={item.title}
          >
            {children}
          </a>
        );
      case 'card':
        return <div className="px-4 py-2">{children}</div>;
      case 'entry':
        return (
          <Link passHref href={item.url} key={item.title}>
            <a>{children}</a>
          </Link>
        );
      case 'nested-card':
        return <div className="px-4">{children}</div>;
      case 'nested-entry':
        return (
          <button
            data-test={`nav-${current}-${item.title}`}
            className="w-full"
            key={item.title}
          >
            {children}
          </button>
        );
      default:
        return <></>;
    }
  };

  return (
    <NavItemWrapper>
      {item.dividerTop && <div className="h-px w-full bg-grey-300" />}
      <div
        data-test={`nav-${current}-${item.title}`}
        className={cn(
          'relative flex w-full cursor-pointer items-center justify-between rounded-sm bg-other-white px-4 py-2 text-primary-main focus:outline-hidden',
          item.itemType === 'card' ||
            (item.itemType === 'nested-card' && 'h-16'),
          (item.itemType === 'card' || item.itemType === 'nested-card') &&
            'rounded-lg border-2 border-grey-50',
          className
        )}
        style={
          item.itemType === 'card' || item.itemType === 'nested-card'
            ? {
                ...(item.itemType === 'card' || item.itemType === 'nested-card'
                  ? {
                      backgroundImage: `url(${item.src})`,
                    }
                  : {}),
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center right',

                // toggle here if the card image is covering the card, which is provided to us by marketing team.
                // backgroundSize: 'cover',
                // backgroundPosition: 'center',
              }
            : {}
        }
        onClick={onClick}
      >
        <div
          className={cn(
            'flex items-center',
            (item.itemType === 'nested-card' || item.itemType === 'card') &&
              'text-primary-main',
            item.itemType === 'entry' &&
              item.reducedTextSize &&
              'text-xs! font-normal'
          )}
        >
          {item.title}
        </div>
        {item.itemType === 'entry' ? (
          item.src ? (
            <img
              src={item.src}
              alt={`Product: ${item.title}`}
              className="h-12 w-12"
            />
          ) : null
        ) : (
          <IconChevronRight className="h-8 w-6 text-primary-main" />
        )}
      </div>
      {item.dividerBottom && <div className="h-px w-full bg-grey-300" />}
    </NavItemWrapper>
  );
};

export default NavigationLink;
