import {
  AgeSegmentationIds,
  AllergySegmentationIds,
  BreedSegmentationIds,
  CatNutritionSegmentationIds,
  NutritionSegmentationIds,
} from 'constants/segmentation';
import { InputProductType } from 'interfaces/api-input/input-product-type';
import { axios } from 'utils/axios';

export const PetType = {
  Dog: 'dog',
  Cat: 'cat',
} as const;

export interface Pet {
  id?: string;
  name?: string;
  // Sending null to the server will remove the value
  age?: AgeSegmentationIds | null;
  needs?: Array<NutritionSegmentationIds | CatNutritionSegmentationIds>;
  // Sending null to the server will remove the value
  breed?: BreedSegmentationIds | null;
  allergies?: Array<AllergySegmentationIds>;
  food_types?: Array<InputProductType>;
  pet_type: (typeof PetType)[keyof typeof PetType];
  updated_at: string;
}

export type CreatePetPayload = Omit<Pet, 'id' | 'updated_at'>;
export type UpdatePetPayload = Omit<Pet, 'updated_at'>;

/**
 * Fetches all pets for a given customer
 */
export const getPets = (customerId: number): Promise<Pet[]> =>
  axios
    .get(`${process.env.API_ORIGIN_PETS}?customer_id=${customerId}`)
    .then(({ data }) => data);

/**
 * Fetches a single pet by ID
 */
export const getPet = (petId: string): Promise<Pet> =>
  axios.get(`${process.env.API_ORIGIN_PETS}/${petId}`).then(({ data }) => data);

/**
 * Creates a new pet
 */
export const createPet = (pet: CreatePetPayload): Promise<Pet> =>
  axios
    .post(`${process.env.API_ORIGIN_PETS}`, { pet })
    .then(({ data }) => data);

/**
 * Updates an existing pet
 */
export const updatePet = (pet: UpdatePetPayload): Promise<Pet> =>
  axios.put(`${process.env.API_ORIGIN_PETS}/${pet.id}`, { pet });

/**
 * Deletes a pet by ID
 */
export const deletePet = (petId: string): Promise<void> =>
  axios.delete(`${process.env.API_ORIGIN_PETS}/${petId}`);
