import { NinetailedProvider } from '@ninetailed/experience.js-next';
import { NinetailedInsightsPlugin } from '@ninetailed/experience.js-plugin-insights';
import NinetailedPreviewPlugin from '@ninetailed/experience.js-plugin-preview';
import useIsClient from 'hooks/common/use-is-client';
import { useEffect, useMemo } from 'react';
import useSWR from 'swr';
import {
  InternalTrackingPlugin,
  TestGtmPlugin,
  getAllAudiences,
  getAllExperiences,
  getGtmPlugin,
} from 'utils/ninetailed';
import { omitNullableHandler } from 'utils/type-helper';

type Props = {
  /** If true, Preview is enable  */
  previewEnabled?: boolean;
};

export const STORAGE_KEY = 'pd:preview';

/**
 * Wrapper for NinetailedProvider to inject preview plugin dynamically
 * @see https://petsdeli.atlassian.net/wiki/x/CwDqeQ
 */
const NinetailedProviderWrapper: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  previewEnabled = false,
}) => {
  const { isClient } = useIsClient();
  const { data, mutate } = useSWR(
    'plugins-data',
    () => {
      if (!previewEnabled) {
        return undefined;
      }
      return Promise.all([getAllAudiences(), getAllExperiences()]);
    },
    {
      revalidateOnFocus: false,
    }
  );

  /**
   * Expose mutate in a case we need to force update experience entry
   */
  useEffect(() => {
    (window as any).pluginMutate = mutate;
  }, [mutate]);

  const previewPlugin = useMemo(() => {
    if (!data) {
      return null;
    }

    const errorEntries: Array<any> = [];

    // Remove error entries which has circular references
    // because previewPlugin uses JSON.stringify internally
    const filtered = data[1].filter((v) => {
      try {
        JSON.stringify(v);
        return true;
      } catch (error) {
        errorEntries.push(v);
        return false;
      }
    });

    console.warn('circular references found', { errorEntries });

    return new NinetailedPreviewPlugin({
      audiences: data[0] as any,
      experiences: filtered,
      onOpenExperienceEditor: (experience) => {
        window.open(
          `https://app.contentful.com/spaces/s8qothrtqrf9/environments/${process.env.CONTENTFUL_ENV}/entries/${experience.id}`,
          '_blank'
        );
      },
    });
  }, [data]);

  if (isClient && !previewPlugin && previewEnabled) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <p>Preview plugin initializing</p>
      </div>
    );
  }

  return (
    <NinetailedProvider
      clientId={process.env.NINETAILED_API_KEY}
      environment={process.env.NINETAILED_ENV}
      requestTimeout={3000}
      componentViewTrackingThreshold={0}
      plugins={[
        getGtmPlugin(),
        new NinetailedInsightsPlugin(),
        new InternalTrackingPlugin(),
        new TestGtmPlugin(),
        previewPlugin,
      ].filter(omitNullableHandler)}
    >
      {children}
    </NinetailedProvider>
  );
};

export default NinetailedProviderWrapper;
