import RedirectComponent from 'components/redirect-component';
import SpecialTestContainer from 'components/special-test-container';
import { ExperienceTrackingContextProvider } from 'contexts/experience-tracking';
import GlobalTestHolder from 'contexts/global-test';
import { NinetailedAbTestProvider } from 'contexts/ninetailed-ab-test';
import NinetailedProviderWrapper, {
  STORAGE_KEY,
} from 'contexts/ninetailed-provider-wrapper';
import { PetProfileProvider } from 'contexts/pet-profile';
import { ReferalProvider } from 'contexts/referal';
import { ReviewsTestContextProvider } from 'contexts/reviews-test';
import RouteProvider from 'contexts/route';
import { SegmentationProvider } from 'contexts/segmentation';
import { SpecialAbTestContextProvider } from 'contexts/special-ab-test-provider';
import { TrackingContextProvider } from 'contexts/tracking';
import { useLockScrollWhenCookieBotActive } from 'hooks/common/use-cookie-bot-consent';
import ProductConfigurator from 'hooks/product/use-product-configurator';
import 'intl-pluralrules';
import { AppProps, NextWebVitalsMetric } from 'next/app';
import dynamic from 'next/dynamic';
import React from 'react';
import { SWRConfig } from 'swr';
import ErrorBoundary from '../components/error-boundary';
import PageProgress from '../components/page-progress';
import PageViewTrigger from '../components/page-view-trigger';
import { AuthContextProvider } from '../contexts/auth';
import { CartContextProvider } from '../contexts/cart';
import { CommonContextProvider } from '../contexts/common';
import { DomContextProvider } from '../contexts/dom';
import { PetContextProvider } from '../contexts/pet';
import TranslationsProvider from '../contexts/translations';
import Layout from '../modules/layout';
import '../styles/tailwind.css';
import '../utils/logging';
import { EventName, trackEvent } from '../utils/tracking';

const ShopSwitcherAsync = dynamic(() => import('../components/shop-switcher'), {
  ssr: false,
});

const CookieBannerAsync = dynamic(() => import('../components/cookie-banner'), {
  ssr: false,
});

const ToastContainerAsync: any = dynamic(
  () =>
    import('react-toastify').then(({ ToastContainer }: any) => ToastContainer),
  { ssr: false }
);

const App: React.FC<AppProps> = ({ pageProps, Component }) => {
  useLockScrollWhenCookieBotActive();

  return (
    <>
      <TranslationsProvider>
        <PageViewTrigger />
        <ErrorBoundary>
          <NinetailedProviderWrapper
            previewEnabled={
              (process.env.IS_FEATURE_BUILD === 'true' &&
                process.env.IS_LIVE === 'false') ||
              (process.env.NODE_ENV !== 'production' &&
                typeof localStorage !== 'undefined' &&
                !!localStorage.getItem(STORAGE_KEY))
            }
          >
            <SWRConfig
              value={{
                revalidateOnFocus: false,
                revalidateOnReconnect: false,
              }}
            >
              <NinetailedAbTestProvider>
                <ExperienceTrackingContextProvider>
                  <SpecialAbTestContextProvider>
                    <SpecialTestContainer />
                    <RedirectComponent />
                    <PetContextProvider>
                      <ReferalProvider>
                        <CommonContextProvider>
                          <PageProgress />
                          <ToastContainerAsync
                            position="bottom-right"
                            closeOnClick
                            pauseOnHover
                            draggable={false}
                          />
                          <DomContextProvider>
                            <AuthContextProvider>
                              <SegmentationProvider>
                                <PetProfileProvider>
                                  <TrackingContextProvider>
                                    <CartContextProvider>
                                      <ProductConfigurator>
                                        <RouteProvider>
                                          <Layout>
                                            <GlobalTestHolder>
                                              <ReviewsTestContextProvider>
                                                <Component {...pageProps} />
                                                {process.env.SHOP_ID ===
                                                  'CH' && <CookieBannerAsync />}
                                                <ShopSwitcherAsync />
                                              </ReviewsTestContextProvider>
                                            </GlobalTestHolder>
                                          </Layout>
                                        </RouteProvider>
                                      </ProductConfigurator>
                                    </CartContextProvider>
                                  </TrackingContextProvider>
                                </PetProfileProvider>
                              </SegmentationProvider>
                            </AuthContextProvider>
                          </DomContextProvider>
                        </CommonContextProvider>
                      </ReferalProvider>
                    </PetContextProvider>
                  </SpecialAbTestContextProvider>
                </ExperienceTrackingContextProvider>
              </NinetailedAbTestProvider>
            </SWRConfig>
          </NinetailedProviderWrapper>
        </ErrorBoundary>
      </TranslationsProvider>
    </>
  );
};

export function reportWebVitals(metric: NextWebVitalsMetric): void {
  const { label, name, value, id } = metric;

  trackEvent(
    label === 'web-vital' ? EventName.WebVitals : EventName.NextJsMetric,
    {
      attributes: {
        event_name: name,
        event_category:
          label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
        value: Math.round(name === 'CLS' ? value * 1000 : value),
        event_label: id,
        non_interaction: true,
      },
    }
  );
}

export default App;
