import { EffectiveCart } from 'interfaces/cart';
import { DiscountType } from 'interfaces/order';
import { useCallback, useMemo } from 'react';

type Input = {
  effectiveCart?: EffectiveCart;
};

type Output = {
  /** The amount of money that is missing to reach the MOV */
  insufficientMoney: number;
  /* MOV, decimal is omitted from the value e.g 2015 meaning 25€ 15cent" */
  minimumBasketValue: number;
  /** True if cart amount is not sufficient for MOV */
  shouldShowMinimumBasketNotice: boolean;
};

/**
 * Hooks to check minimum order value conditions.
 * @TODO : Logics are differed from getFrozenProductsCount
 */
const useMOVConditions = ({ effectiveCart }: Input): Output => {
  const minimumBasketValue: number = useMemo(() => {
    // This is necessary in order to allow micro-influencers to use a fix coupon and performa a checkout below the standard MOV.
    const hasFixedCoupon = Boolean(
      effectiveCart?.discount?.fixedValue &&
        effectiveCart?.discount?.type === DiscountType.COUPON
    );

    return hasFixedCoupon
      ? process.env.MINIMUM_BASKET_VALUE_FIXED_COUPON
      : process.env.MINIMUM_BASKET_VALUE;
  }, [effectiveCart]);

  const calculateTotalCredits = useCallback(() => {
    return effectiveCart?.discount?.type === DiscountType.CREDIT
      ? effectiveCart?.discount?.totalDiscount
      : 0;
  }, [effectiveCart]);

  const shouldShowMinimumBasketNotice: boolean = useMemo(() => {
    const totalCredits = calculateTotalCredits();
    const cartTotal =
      (effectiveCart?.itemsTotal ?? 0) -
      (effectiveCart?.itemsTotalReduction ?? 0);
    return cartTotal + totalCredits < minimumBasketValue;
  }, [effectiveCart, minimumBasketValue, calculateTotalCredits]);

  const insufficientMoney = useMemo(() => {
    const totalCredits = calculateTotalCredits();
    const cartTotal = effectiveCart?.itemsTotal ?? 0;
    const totalReductions = effectiveCart?.itemsTotalReduction ?? 0;
    return (
      (minimumBasketValue + totalReductions - (cartTotal + totalCredits)) / 100
    );
  }, [effectiveCart, minimumBasketValue, calculateTotalCredits]);

  return {
    insufficientMoney,
    minimumBasketValue,
    shouldShowMinimumBasketNotice,
  };
};

export default useMOVConditions;
